// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  .main_screen header nav ul li a {
    line-height: 18px;
    font-size: 16px;
  }

  .main_screen header a.login_btn {
    font-size: 16px;
  }

  .main_screen header .header_login_wrap .grey_btn {
    height: 50px;
    width: 170px;
    font-size: 16px;
  }

  .main_screen header nav ul li {
    margin-right: 25px;
  }

  .main_logo img {
    max-width: 170px;
  }

  .main_slider_wrap .main_slide_desc {
    max-width: 440px;
  }

  .private .blue_btn, .private .green_btn {
    font-size: 16px;
  }

                                          //TEXT PAGE

  .recommendation_slider h4 {
    text-align: center;
    margin-bottom: 30px;
  }
}


// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .main_screen header .header_login_wrap {
    display: none;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .main_screen header a.login_btn {
    max-width: 144px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .main_screen {
    padding-bottom: 60px;
  }

  .main_screen header {
    position: absolute;
    width: 100%;
    z-index: 200;
  }

  .main_screen header .header_login_wrap .grey_btn {
    margin-left: auto;
    margin-right: auto;
  }

  .main_screen .top_contacts_col {
    position: relative;
    flex-direction: column;
    justify-content: space-around;
  }

  .main_screen header .main_header_col .hamburger {
    position: absolute;
    top: 24px;
    right: 15px;
    display: inline-block;
    padding: 0;
    outline: none;    
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #0092ee;
  }

  .main_screen header .main_header_col {
    flex-wrap: wrap;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main_screen header .main_header_col nav {
    display: none;
    width: 100%;
  }

  .main_screen header nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main_screen header nav ul li {
    margin-right: 0;
  }

  .main_screen header nav ul li a {
    line-height: 50px;
    border-top: 0;
    border-bottom: 2px solid transparent;
  }

  .main_screen header nav ul li a:hover {
    border-top: 0;
    border-bottom: 2px solid #1991eb;
  }

  .main_slider_wrap .col_main_slide_left {
    text-align: center;
    margin-bottom: 30px;
  }

  .main_slider_wrap .main_slide_desc {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .main_slider_wrap h3 {
    margin-bottom: 26px;
    font-size: 26px;
  }

  .main_slider_wrap h2 {
    font-size: 34px;
    line-height: 40px;
  }

  .main_slider_wrap p {
    font-size: 16px;
    line-height: 22px;
  }

  .main_slider_wrap .main_slide_desc p {
    font-size: 14px;
    line-height: 22px;
  }

  .main_slider_wrap .main_slide_desc a.blue_btn {
    height: 50px;
    width: 180px;
    font-size: 16px;
  }

  .main_slider_wrap .main_slide {
    
    h3 {
      display: none;
    }
    
    &:nth-of-type(1){

      h3 {
        display: block;
      }
    }
  }

  .mini_gallery {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mini_gallery_slider {
    top: 0;
    position: relative;

    img {
      margin-bottom: 20px;
    }
  }

  .last_news h2 {
    font-size: 26px;
    text-align: center;
  }

  .last_news {
    padding-top: 75px;
    padding-bottom: 40px;
  }

  .last_news a.news_wrap {
    margin-bottom: 40px;
  }

  .partners {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .partners h3 {
    width: 100%;
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }

  .row_partner {

    img {
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  footer {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  footer .col_footer_left ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  footer .col_footer_left li {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  footer .col_footer_center p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  footer .col_footer_right ul {
    max-width: 256px;
    margin-left: auto;
    margin-right: auto;
  }

  .col_footer_left {
    margin-bottom: 10px;
  }

  .col_footer_center {
    margin-bottom: 20px;
  }

                                                //ORDER FORM PAGE
  .order_form_header h2 {
    font-size: 26px;
  }

  .order_form_anchors h4 {
    width: 120px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24px;
  }

  .order_form_anchors a {
    height: 50px;
    width: 170px;
    font-size: 16px;
  }

  .order_form_anchors {
    justify-content: flex-start;
  }

  .order_form_body h4 {
    font-size: 16px;
  }

  .order_form_body button {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .order_form_body .chekbox_wrap {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .order_form_body .pay_ul {
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin-right: 10px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .order_form_body .policy_anchor {
    margin-right: auto;
    margin-top: 0;
  }

  .order_form_body {
    padding-bottom: 80px;
  }

                                      //LOGIN PAGE

  .content_header h2 {
    font-size: 26px;
  }

                                        //PRIVATE

  .private h3 {
    font-size: 16px;
  }

  .private .blue_btn, .private .green_btn {
    font-size: 14px;
  }

                                    //TEXT PAGE

  .text_content h1 {
    margin-bottom: 20px;
    font-size: 32px;
  }

  .text_content h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }

  .text_content h3 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .text_content h4 {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .text_content h5 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .text_content h6 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .text_content p {
    line-height: 24px;
  }

  .text_content blockquote {
    line-height: 24px;
  }

  .recommend_slide {
    max-width: 260px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  //MAIN SCREEN

  .main_screen {
    padding-bottom: 30px;
  }

  .main_screen .main_slogan h3 {
    font-size: 20px;
    text-align: center;
  }

  .main_screen .main_slogan h1 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }

  .main_screen .main_slogan h4 {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .main_screen .video_wrap {
    text-align: center;
  }

  .main_screen .main_slogan p {
    text-align: center;
  }

  .main_waiting .row {
    flex-direction: column;
  }

  .main_waiting h2 {
    font-size: 22px;
    text-align: center;
  }

  .main_waiting a {
    display: inline-block;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }

  .main_waiting .col-md-4 {
    margin-bottom: 30px;
    text-align: center;
  }

  //LOCATION

  .location {
    padding-top: 20px;
  }

  .location .location_digits ul {
    flex-direction: column;
    align-items: center;
  }

  .location .location_header p {
    font-size: 14px;
    line-height: 22px;
  }

  .gallery .timer ul li {
    font-size: 14px;
    line-height: 48px;
  }

  .gallery .timer ul li b {
    margin-right: 6px;
    font-size: 26px;
  }

  //FOOTER

  footer {
    line-height: inherit;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  footer .footer_row {
    height: auto;
  }

  .footer_logo {

    img {
      margin-bottom: 20px;
    }
  }

  footer .footer_phone_wrap {
    margin-bottom: 20px;
  }

  footer .footer_vk {
    margin-bottom: 20px;
  }

  //CYBERSPORT PAGE

  .headline .img_wrap h1 {
    bottom: 30px;
    font-size: 26px;
  }

  .headline .cloud h2 {
    font-size: 18px;
    line-height: 28px;
  }

  .list ul li p {
    font-size: 16px;
    line-height: 26px;
  }

  .reserve {
    padding-top: 40px;
    padding-bottom: 80px;
    background: none;
  }

  .reserve h2 {
    margin-top: -60px;
  }

  .reserve p {
    font-size: 16px;
    line-height: 26px;
  }

  .reserve .reserve_btn {
    max-width: 360px;
  }

  .reserve_anchor_row .first_reserv_anchor {
    margin-bottom: 30px;
    text-align: center;
  }

  .reserve_anchor_row .second_reserv_anchor {
    text-align: center;
  }

  //CAMP LIFE PAGE

  .main_description .events h2 {
    font-size: 26px;
  }

  //SOCHI PAGE

  .sochi_slider {
    margin-bottom: 50px;
  }

  .sochi_slider h2 {
    padding-left: 0;
    text-align: center;
  }

  .sochi_slider .sochi_slider_wrap {
    padding-left: 0;
  }

  .sochi_slider_arrow_wrap_1, .sochi_slider_arrow_wrap_2, .sochi_slider_arrow_wrap_3 {
    position: relative;
  }

  .slider_control_col {
    margin-bottom: 20px;
  }

  .sochi_slider .row {
    margin-bottom: 110px;
  }

  //ABOUT COMPANY PAGE

  .about_company .left_col {
    padding-left: 15px;
    text-align: center;
  }

                                      //ORDER FORM PAGE

  input[type=text], input[type=tel], input[type=email], input[type=date], input[type=password] {
    height: 50px;
    margin-bottom: 25px;
    font-size: 16px;
    padding-left: 15px;
  }

  label {
    margin-bottom: 8px;
  }

  .order_form_body .informer {
    padding-left: 0;
    padding-top: 50px;
    background: url("../img/icons/info_form.png") no-repeat top center;
    text-align: center;
  }

  .order_form_body .ui-selectmenu-button.ui-button {
    height: 50px;
    font-size: 16px;
  }

                                                  //LOGIN PAGE

  .content_header {
    padding-top: 93px;
  }

                                                  //PRIVATE PAGE

  .private {
    padding-bottom: 60px;
  }

  .private .table_header {
    display: none;
  }

  .private h3 {
    text-align: center;
  }

  .private .table_body .t_row {
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }

  .private .table_body .t_col {
    height: 50px;
    width: 100% !important;
    align-items: center !important;
    padding-left: 0 !important;
    justify-content: center;
  }

                                                      //TEXT PAGE

  .breadcrumbs {
    padding-top: 70px;
  }

  .text_content .breadcrumbs ul {

    margin-bottom: 0;
  }
}


// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .main_screen .top_contacts_col {
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    text-align: center;
  }

  .main_screen .top_contacts_col a {
    display: block;
  }

  .main_screen header nav ul li a {
    line-height: 34px;
  }

  .main_screen header .main_header_col nav {
    padding-top: 15px;
  }

  .main_slider_wrap h3 {
    font-size: 24px;
  }

  .main_slider_wrap h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .main_slider_wrap p {
    font-size: 14px;
  }

  .main_slider_wrap .main_slide_desc:before,
  .main_slider_wrap .main_slide_desc:after {
    display: none;
  }

  .main_slider_wrap .main_slide_desc {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mini_gallery_slider {
    text-align: center;
  }

  .last_news h2 {
    font-size: 24px;
  }

  footer .col_footer_left ul {
    flex-direction: column;
    align-items: center;
  }

  footer .col_footer_left li {
    margin-right: 0;
  }

  footer .col_footer_right ul {
    max-width: 184px;
  }

  .main_screen .top_contacts_col {
    font-size: 12px;
  }


                                    //ORDER FORM PAGE
  .order_form_anchors {
    flex-wrap: wrap;
  }

  .order_form_header h2 {
    font-size: 24px;
  }

  .order_form_anchors h4 {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    text-align: center;
  }

  .order_form_anchors a {
    width: 100%;
    margin-right: 0;
    border-radius: 10px;
    margin-bottom: 10px;
  }

                                                              //LOGIN PAGE

  .content_header h2 {
    font-size: 24px;
  }
                                                        //TEXT PAGE

  .text_content .breadcrumbs ul {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .favor {
    text-align: center;
  }

  .share {
    margin-top: 20px;
    text-align: center;
  }

  .text_content blockquote {
    background: none;
  }
}